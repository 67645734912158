<template>
    <div class="hello" style="text-align: center; color: grey;">
        <h1>{{ msg }}</h1>
        <h2>Приветствуем Вас на сайте ООО&nbsp;"АИС"!</h2>
        <br>
        <div v-if="!isLoggedIn">
            <div class="link" style="font-size: larger; text-decoration: underline; padding-top: 20px; padding-bottom: 50px;"
                 v-if="!isLoggedIn" v-on:click="goToHref(httpPath + 'login');">Войти</div>
            <div class="link" style="font-size: larger; text-decoration: underline;"
                 v-if="!isLoggedIn" v-on:click="goToHref(httpPath + 'registration');">Зарегистрироваться</div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'HelloWorld',
    props: {
        msg: String
    },
    data: function(){
        return{
            httpPath: ""
        };
    },
    created: function(){
        this.httpPath = this.$store.getters.httpPath;
    },
    computed : {
        isLoggedIn : function(){
            return this.$store.getters.isLoggedIn;
        }
    },
    methods: {
        logout: function () {
            this.goToHref(this.httpPath + 'logout');
        },
        goToHref: function(href){
            window.scrollTo(0, 0);
            this.$router.push(href);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
</style>
