<template>
    <div>
        
        <div v-bind:class="{'readonly': mode === 'delete', 'disabled': !enabled}">
            <div style="width: 100%; text-align: center; font-size: large; padding-bottom: 10px;">Задача</div>
            <div style="margin-top: 5px;">
                <div style="display: inline-block; width: 400px;">
                    <div style="display: inline-block; width: 70px;" v-bind:class="{'disabled': true}">Автор</div>
                    <UserInput v-model="task.creator" element-id="creator" v-bind:enabled="false" />
                </div>
                <div style="display: inline-block; width: 490px;">
                    <div style="display: inline-block; width: 160px;">Исполнитель</div>
                    <UserInput v-model="task.executor" element-id="executor" v-bind:class="{'readonly': task.creator.id !== user.id && task.executor.id !== null }" />
                </div>
            </div>
            <div style="margin-top: 5px;">
                <div style="display: inline-block; width: 400px;">
                    <div style="display: inline-block; width: 70px;">Клиент</div>
                    <OrgInput v-model="task.client" element-id="client" v-bind:class="{'readonly': task.creator.id !== user.id }" />
                </div>
                <div style="display: inline-block; width: 490px;">
                    <div style="display: inline-block; width: 160px;">Срок выполнения</div>
                    <input type="date" v-model="task.dateMax" v-bind:class="{'readonly': task.creator.id !== user.id }">
                </div>
            </div>
            <div style="width: 1000px;">
                <div style="margin-top: 5px; display: inline-block; width: 800px;">
                    <div>Описание</div>
                    <textarea v-model="task.text" cols="55" rows="5" v-bind:class="{'readonly': task.creator.id !== user.id }"></textarea>
                </div>
                <div style="margin-top: 5px; display: inline-block; width: 200px; vertical-align: top; padding-top: 30px;">
                    <div style="display: inline-block; width: 110px;">Состояние</div>
                    <WorksStatesSelect v-model="task.state" element-id="state" v-bind:class="{'readonly': task.creator.id !== user.id }" />
                </div>
            </div>
            <!--
            <div style="padding-top: 10px;">
                <div style="display: inline-block; width: 160px;">Телефоны</div>
                <input v-model="org.phones" placeholder="Введите телефоны" type="text" size="30">
            </div>
            -->
            
            <div style="margin-top: 10px; width: 1050px; height: 205px; border: solid 1px gray; border-radius: 5px; padding-left: 10px;">
                <div>
                    <div class="btn btn-blue" style="display: inline-block; margin: 3px;"
                        v-on:click="workAdd();">
                        Добавить работу
                    </div>
                </div>
                <div style="background-color: ">
                    <div style="display: inline-block; width: 50px; border: solid 1px gray; text-align: center;">
                        № п/п
                    </div>
                    <div style="display: inline-block; width: 100px; border: solid 1px gray; border-left-width: 0; text-align: center;">
                        Дата/время
                    </div>
                    <div style="display: inline-block; width: 130px; border: solid 1px gray; border-left-width: 0; text-align: center;">
                        Время вып-ния
                    </div>
                    <div style="display: inline-block; width: 200px; border: solid 1px gray; border-left-width: 0; text-align: center;">
                        Исполнитель
                    </div>
                    <div style="display: inline-block; width: 100px; border: solid 1px gray; border-left-width: 0; text-align: center;">
                        Вид работ
                    </div>
                    <div style="display: inline-block; width: 120px; border: solid 1px gray; border-left-width: 0; text-align: center;">
                        Статус
                    </div>
                    <div style="display: inline-block; width: 200px; border: solid 1px gray; border-left-width: 0; text-align: center;">
                        Действия
                    </div>
                </div>
                <div style="width: 1050px; height: 150px; overflow-y: auto;">
                    
                    <div v-for="work in task.works" v-bind:key="work.index" style="border-top: solid 0px gray; width: 950px;"
                        v-bind:class="{'inWaiting': work.state.name === 'ВОжидании', 'inWork': work.state.name === 'ВРаботе', 'done': work.state.name === 'Выполнено'}"
                        v-show="!work.deleted">
                        <div style="border-top: solid 2px gray; width: 908px;"></div>
                        <div style="float: left; display: inline-block; vertical-align: top; overflow-y: hidden; width: 50px; height: 33px; padding-top: 12px; border: solid 1px gray; border-top-width: 0; text-align: center;">
                            {{ work.npp }}
                        </div>
                        <div style="float: left; display: inline-block; vertical-align: top; overflow-y: hidden; width: 100px; height: 41px; padding-top: 4px; border: solid 1px gray; border-top-width: 0; border-left-width: 0; text-align: center;">
                            {{ work.date }} <br> {{ work.time }}
                        </div>
                        <div style="float: left; display: inline-block; vertical-align: top; overflow-y: hidden; width: 127px; padding-left: 3px; height: 41px; padding-top: 4px; border: solid 1px gray; border-top-width: 0; border-left-width: 0; text-align: left;">
                            <div style="display: inline-block; width: 55px;">учт.:</div>{{ ("00" + work.hours).slice(-2) }}ч{{ ("00" + work.minutes).slice(-2) }}м<br>
                            <div style="display: inline-block; width: 55px;">факт.:</div>{{ ("00" + work.hoursFact).slice(-2) }}ч{{ ("00" + work.minutesFact).slice(-2) }}м
                        </div>
                        <div style="display: inline-block; vertical-align: top; word-break: break-all; overflow-x: hidden; overflow-y: hidden; width: 197px; padding-left: 3px; height: 23px; padding-top: 2px; border: solid 1px gray; border-top-width: 0; border-left-width: 0; text-align: left;">
                            {{ work.executor.name }}
                        </div>
                        <div style="display: inline-block; vertical-align: top; overflow-y: hidden; width: 100px; height: 23px; padding-top: 2px; border: solid 1px gray; border-top-width: 0; border-left-width: 0; text-align: center;">
                            {{ work.type.title }}
                        </div>
                        <div style="display: inline-block; vertical-align: top; overflow-y: hidden; width: 120px; height: 23px; padding-top: 2px; border: solid 1px gray; border-top-width: 0; border-left-width: 0; text-align: center;">
                            {{ work.state.title }}
                        </div>
                        <div style="display: inline-block; vertical-align: top; overflow-y: hidden; width: 200px; height: 24px; padding-top: 1px; border: solid 1px gray; border-top-width: 0; border-left-width: 0; text-align: center;">
                            <div class="btn btn-blue" style="padding: 0px;" v-on:click="workEdit(work);">Изменить</div>
                            <div class="btn btn-red" style="padding: 0px; margin-left: 10px;" v-on:click="workDelete(work);" v-show="work.executor.id === user.id">Удалить</div>
                        </div>
                        <div style="vertical-align: top; overflow-y: hidden; width: 620px; padding-left: 3px; height: 19px; border: solid 1px gray; border-top-width: 0; border-left-width: 0; text-align: left;">
                            {{ work.text }}
                        </div>
                    </div>
                    <div style="border-top: solid 2px gray; width: 908px;"></div>
                </div>
                
            </div>
            
            <div style="margin-top: 5px; margin-bottom: -12px; text-align: left; background-color: red; color: white;" v-show="message !== ''">
                <strong>{{ message }}</strong>
            </div>
        </div>
        
        <div v-bind:class="{'disabled': !enabled}">
            <div class="btn btn-blue" v-on:click="save();" v-show="mode === 'edit'" style="margin-left: 200px; margin-top: 15px;">Записать</div>
            <!--
            <div class="btn btn-background-red" v-on:click="del();" v-show="mode === 'delete'" style="margin-left: 135px; margin-top: 15px;">Удалить</div>
            -->
            <div class="btn btn-blue" v-on:click="close();" style="margin-left: 200px; margin-top: 15px;">Отмена</div>
        </div>
        
        <div id="formWork" v-show="formWork.show" v-bind:class="{'background-white': formWork.mode === 'edit', 'background-light-red': formWork.mode === 'delete'}">
            
            <div class="btn btn-blue" v-on:click="formWorkClose();" v-bind:style="{'position': 'absolute', 'left': formWork.buttonClose.left + 'px', 'top': formWork.buttonClose.top + 'px'}">X</div>
            
            <div style="width: 100%; text-align: center; font-size: large; padding-bottom: 10px;">Работа</div>
            
            <div style="margin-top: 5px;">
                <div style="display: inline-block; width: 280px;">
                    <div style="display: inline-block; width: 50px;">Дата</div>
                    <input type="date" v-model="formWork.work.date" v-bind:class="{'readonly': formWork.work.executor.id !== user.id}">
                </div>
                <div style="display: inline-block; width: 230px;">
                    <div style="display: inline-block; width: 65px;">Время</div>
                    <input type="time" v-model="formWork.work.time" v-bind:class="{'readonly': formWork.work.executor.id !== user.id}">
                </div>
                <div style="display: inline-block; width: 460px;">
                    <div style="display: inline-block; width: 130px;">Исполнитель</div>
                    <UserInput v-model="formWork.work.executor" element-id="work-executor" v-bind:class="{'readonly': formWork.work.executor.id !== user.id}" />
                </div>
            </div>
            <div style="margin-top: 10px;">
                <div style="display: inline-block; width: 210px;">
                    <div style="display: inline-block; width: 90px;">Часы учт.</div>
                    <input type="number" v-model="formWork.work.hours" style="width: 70px;" v-bind:class="{'readonly': formWork.work.executor.id !== user.id}">
                </div>
                <div style="display: inline-block; width: 270px;">
                    <div style="display: inline-block; width: 120px;">Минуты учт.</div>
                    <input type="number" v-model="formWork.work.minutes" style="width: 70px;" v-bind:class="{'readonly': formWork.work.executor.id !== user.id}">
                </div>
                <div style="display: inline-block; width: 220px;">
                    <div style="display: inline-block; width: 110px;">Часы факт.</div>
                    <input type="number" v-model="formWork.work.hoursFact" style="width: 70px;" v-bind:class="{'readonly': formWork.work.executor.id !== user.id}">
                </div>
                <div style="display: inline-block; width: 220px;">
                    <div style="display: inline-block; width: 130px;">Минуты факт.</div>
                    <input type="number" v-model="formWork.work.minutesFact" style="width: 70px;" v-bind:class="{'readonly': formWork.work.executor.id !== user.id}">
                </div>
            </div>
            <div style="width: 1000px;">
                <div style="margin-top: 5px; display: inline-block; width: 800px;">
                    <div>Описание</div>
                    <textarea v-model="formWork.work.text" cols="55" rows="5"></textarea>
                </div>
            </div>
            <div style="margin-top: 10px;">
                <div style="display: inline-block; width: 280px;">
                    <div style="display: inline-block; width: 110px;">Вид работы</div>
                    <WorksTypesSelect v-model="formWork.work.type" element-id="work-type" v-bind:class="{'readonly': formWork.work.executor.id !== user.id}" />
                </div>
                <div style="display: inline-block; width: 270px;">
                    <div style="display: inline-block; width: 110px;">Состояние</div>
                    <WorksStatesSelect v-model="formWork.work.state" element-id="work-state" v-bind:class="{'readonly': formWork.work.executor.id !== user.id}" />
                </div>
            </div>
            
            <div style="margin-top: 15px; margin-bottom: -20px; text-align: left; background-color: red; color: white;" v-show="formWork.message !== ''">
                <strong>{{ formWork.message }}</strong>
            </div>
            
            <div style="margin-top: 20px;">
                <div v-show="formWork.mode === 'edit'" v-bind:class="{'btn': true, 'btn-blue': true, 'disabled': formWork.work.executor.id !== user.id}" v-on:click="formWorkOk();"
                     style="margin-left: 250px; margin-top: 15px; width: 100px; text-align: center;">
                    OK
                </div>
                <div v-show="formWork.mode === 'delete'" class="btn btn-background-red" v-on:click="formWorkDelete();"
                     style="margin-left: 250px; margin-top: 15px; width: 100px; text-align: center;">
                    Удалить
                </div>
                <div class="btn btn-blue" v-on:click="formWorkClose();"
                     style="margin-left: 220px; margin-top: 15px; width: 100px; text-align: center;">
                    Отмена
                </div>
            </div>
            
        </div>
        
    </div>
</template>

<script>

import BaseFunctions from '../../base_functions.js';

import Axios from 'axios'
import WorksTypesSelect from '../BaseData/WorksTypesSelect.vue'
import WorksStatesSelect from '../BaseData/WorksStatesSelect.vue'
import UserInput from '../Users/UserInput.vue'
import OrgInput from '../Orgs/OrgInput.vue'

export default {
    name: 'PageTask',
    props: {
        msg: String
    },
    components: {
        WorksTypesSelect,
        WorksStatesSelect,
        UserInput,
        OrgInput
    },
    data: function(){
        return {
            message: "",
            mode: "",
            enabled: true,
            task: {
                id: "",
                creator: {
                    id: null,
                    name: "",
                    email: ""
                },
                executor: {
                    id: null,
                    name: "",
                    email: ""
                },
                client: {
                    id: null,
                    name: "",
                    title: ""
                },
                dateMax: "",
                text: "",
                state: {
                    id: null,
                    name: "",
                    title: "",
                    order: 0
                },
                works: []
            },
            worksIndexMax: 0,
            formWork: {
                message: "",
                show: false,
                mode: "",
                buttonClose: {
                    left: 983,
                    top: 2
                },
                work: {
                    index: null,
                    id: null,
                    executor: {
                        id: null,
                        name: "",
                        email: ""
                    },
                    date: null,
                    time: null,
                    hours: 0,
                    minutes: 0,
                    hoursFact: 0,
                    minutesFact: 0,
                    type: {
                        id: null,
                        name: "",
                        title: ""
                    },
                    state: {
                        id: null,
                        name: "",
                        title: ""
                    }
                    
                }
            }
        };
    },
    created: function(){
        this.$parent.$on('item-add', this.updateNew);
        this.$parent.$on('item-edit', this.updateEdit);
        /*
        this.$parent.$on('item-delete', this.updateDelete);
        */
    },
    computed:{
        user: function(){
            return this.$store.getters.user;
        }
    },
    methods: {
        
        updateNew: function(){
            
            this.message = "";
            this.formWork.message = "";
            this.task.id = null;
            this.task.creator.id = this.user.id;
            this.task.creator.name = this.user.name;
            this.task.creator.email = this.user.email;
            this.$emit("element-update", {
                elementId: "creator",
                value: this.task.creator
            });
            this.task.executor.id = this.user.id;
            this.task.executor.name = this.user.name;
            this.task.executor.email = this.user.email;
            this.$emit("element-update", {
                elementId: "executor",
                value: this.task.executor
            });
            this.task.client.id = null;
            this.task.client.name = "";
            this.task.client.title = "";
            this.$emit("element-update", {
                elementId: "client",
                value: this.task.client
            });
            this.task.dateMax = null;
            this.task.text = "";
            this.task.state.id = null;
            this.task.state.name = "ВОжидании";
            this.task.state.title = "";
            this.task.state.order = 0;
            this.$emit("element-update", {
                elementId: "state",
                value: this.task.state
            });
            this.task.works = [];
            
            this.mode = "edit";
            
        },
        
        updateEdit: function(task){
            
            this.message = "";
            this.itemFill(task);
            this.mode = "edit";
            
            this.updateWorks();
           
        },
        
        updateDelete: function(task){
            if(task){
                //
            }
            /*
            this.message = "";
            this.itemFill(org);
            this.mode = "delete";
            */
        },
        
        itemFill: function(task){
            
            this.task.id = task.id;
            this.task.creator.id = task.creatorId;
            this.task.creator.name = task.creatorName;
            this.task.creator.email = "";
            this.$emit("element-update", {
                elementId: "creator",
                value: this.task.creator
            });
            
            this.task.executor.id = task.executorId;
            this.task.executor.name = task.executorName;
            this.task.executor.email = "";
            this.$emit("element-update", {
                elementId: "executor",
                value: this.task.executor
            });
            
            this.task.client.id = task.clientId;
            this.task.client.name = "";
            this.task.client.title = task.clientTitle;
            this.$emit("element-update", {
                elementId: "client",
                value: this.task.client
            });
            
            this.task.dateMax = task.dateMax;
            this.task.text = task.text;
            
            this.task.state.id = task.stateId;
            this.task.state.name = task.stateName;
            this.task.state.title = task.stateTitle;
            this.task.state.order = 0;
            this.$emit("element-update", {
                elementId: "state",
                value: this.task.state
            });
            
        },
        
        save: function (){
            
            /*
            if(this.task.id === null){
                
                Axios
                    .post('/api/tasks/', {
                        
                        //creatorId: this.task.creator.id,
                        //executorId: this.task.executor.id,
                        //clientId: this.task.client.id,
                        //text: this.task.text,
                        //dateMax: this.task.dateMax,
                        //stateId: this.task.state.id
                    })
                    .then(response => {
                        let task = response.data;
                        this.task.id = task.id;
                        //alert("Данные успешно записаны");
                        //this.$router.push({ path: '/adm/task' });
                        this.$emit("item-save");
                        this.close();
                    })
                    .catch(e => {
                        this.message = e;
                    });
                
            }else{
                
                Axios
                    .put('/api/tasks/' + this.task.id, {
                        task: this.task
                        //creatorId: this.task.creator.id,
                        //executorId: this.task.executor.id,
                        //clientId: this.task.client.id,
                        //text: this.task.text,
                        //dateMax: this.task.dateMax,
                        //stateId: this.task.state.id
                    })
                    .then(response => {
                        if(response){
                            //
                        }
                        //this.task = response.data;
                        //alert("Данные успешно записаны");
                        //this.$router.push({ path: '/adm/org' });
                        this.$emit("item-save");
                        this.close();
                    })
                    .catch(e => {
                        this.message = e;
                    });
                
            }
            */
            Axios
                .post('/api/tasks/', {
                    task: this.task
                })
                .then(response => {
                    if(response){
                        //
                    }
                    //this.task = response.data;
                    //alert("Данные успешно записаны");
                    //this.$router.push({ path: '/adm/org' });
                    this.$emit("item-save");
                    this.close();
                })
                .catch(e => {
                    this.message = e;
                });
            
        },
        del: function(){
            
            /*
            Axios
                .delete('/api/orgs/' + this.org.id)
                .then(response => {
                    if(response.status === 204){
                        this.$emit("item-delete");
                        this.close();
                    }else{
                        this.message = "Ошибка! Не предусмотренный код ответа " + response.status + " обратитесь к разработчику.";
                    }
                })
                .catch(e => {
                    this.message = e;
                });
            */
        },
        close: function(){
            this.$emit("item-close");
        },
        
        updateWorks: function(){
            
            this.task.works = [];
            
            Axios
            .post('/api/works/list', {
                taskId: this.task.id
            })
            .then(response => {
                this.message = "";
                let works = response.data;
                
                this.task.works = [];
                
                this.worksIndexMax = 0;
                
                for(let i=0; i < works.length; i++){
                    
                    let workDB = works[i];
                    
                    let work = {
                        index: this.worksIndexMax,
                        npp: i+1,
                        id: workDB.id,
                        executor: {
                            id: workDB.executorId,
                            name: workDB.executorName,
                            email: ""
                        },
                        date: workDB.date,
                        time: workDB.time,
                        hours: workDB.hours,
                        minutes: workDB.minutes,
                        hoursFact: workDB.hoursFact,
                        minutesFact: workDB.minutesFact,
                        text: workDB.text,
                        type: {
                            id: workDB.typeId,
                            name: workDB.typeName,
                            title: workDB.typeTitle
                        },
                        state: {
                            id: workDB.stateId,
                            name: workDB.stateName,
                            title: workDB.stateTitle
                        },
                        deleted: false
                    };
                    
                    this.task.works.push(work);
                    
                    this.worksIndexMax++;
                }
                
            } )
            .catch(e => {
                this.message = e;
            });
            
        },
        
        workAdd: function(){
            
            this.formWork.message = "";
            this.formWork.mode = "edit";
            
            let work = this.formWork.work;
            
            work.index = null;
            work.id = null;
            //work.executor.id = this.task.executor.id;
            //work.executor.name = this.task.executor.name;
            //work.executor.email = this.task.executor.email;
            work.executor.id = this.user.id;
            work.executor.name = this.user.name;
            work.executor.email = this.user.email;
            this.$emit("element-update", {
                elementId: "work-executor",
                value: work.executor
            });
            let now = new Date();
            work.date = BaseFunctions.dateToString(now);
            work.time = BaseFunctions.timeToString(now);
            work.hours =  0;
            work.minutes = 0;
            work.hoursFact = 0;
            work.minutesFact = 0;
            work.text = this.task.text;
            work.type.id = null;
            work.type.name = "";
            work.type.title = "";
            this.$emit("element-update", {
                elementId: "work-type",
                value: work.type
            });
            work.state.id = null;
            work.state.name = "";
            work.state.title = "";
            this.$emit("element-update", {
                elementId: "work-state",
                value: work.state
            });
            
            this.enabled = false;
            this.formWork.show = true;
            
        },
        
        workEdit: function(currentWork){
            
            this.formWork.message = "";
            this.formWork.mode = "edit";
            
            let work = this.formWork.work;
            
            work.index = currentWork.index;
            work.id = currentWork.id;
            work.executor.id = currentWork.executor.id;
            work.executor.name = currentWork.executor.name;
            work.executor.email = currentWork.executor.email;
            this.$emit("element-update", {
                elementId: "work-executor",
                value: work.executor
            });
            work.date = currentWork.date;
            work.time = currentWork.time;
            work.hours =  currentWork.hours;
            work.minutes = currentWork.minutes;
            work.hoursFact = currentWork.hoursFact;
            work.minutesFact = currentWork.minutesFact;
            work.text = currentWork.text;
            work.type.id = currentWork.type.id;
            work.type.name = currentWork.type.name;
            work.type.title = currentWork.type.title;
            this.$emit("element-update", {
                elementId: "work-type",
                value: work.type
            });
            work.state.id = currentWork.state.id;
            work.state.name = currentWork.state.name;
            work.state.title = currentWork.state.title;
            this.$emit("element-update", {
                elementId: "work-state",
                value: work.state
            });
            
            this.enabled = false;
            this.formWork.show = true;
            
        },
        
        workDelete: function(currentWork){
            
            this.formWork.message = "";
            this.formWork.mode = "delete";
            
            let work = this.formWork.work;
            
            work.index = currentWork.index;
            work.id = currentWork.id;
            work.executor.id = currentWork.executor.id;
            work.executor.name = currentWork.executor.name;
            work.executor.email = currentWork.executor.email;
            this.$emit("element-update", {
                elementId: "work-executor",
                value: work.executor
            });
            work.date = currentWork.date;
            work.time = currentWork.time;
            work.hours =  currentWork.hours;
            work.minutes = currentWork.minutes;
            work.hoursFact = currentWork.hoursFact;
            work.minutesFact = currentWork.minutesFact;
            work.text = currentWork.text;
            work.type.id = currentWork.type.id;
            work.type.name = currentWork.type.name;
            work.type.title = currentWork.type.title;
            this.$emit("element-update", {
                elementId: "work-type",
                value: work.type
            });
            work.state.id = currentWork.state.id;
            work.state.name = currentWork.state.name;
            work.state.title = currentWork.state.title;
            this.$emit("element-update", {
                elementId: "work-state",
                value: work.state
            });
            
            this.enabled = false;
            this.formWork.show = true;
            
        },
        
        formWorkClose: function(){
            
            this.enabled = true;
            this.formWork.show = false;
            
        },
        
        formWorkOk: function(){
            
            this.formWork.message = "";
            if(this.formWork.work.type.id === null){
                this.formWork.message = "Ошибка! Не указан вид работ.";
                return;
            }
            
            let work = null;
            
            let works = this.task.works;
            
            if(this.formWork.work.index === null){
                work = {
                    index: this.worksIndexMax,
                    npp: 0,
                    id: this.formWork.work.id,
                    executor: {
                        id: this.formWork.work.executor.id,
                        name: this.formWork.work.executor.name,
                        email: this.formWork.work.executor.email
                    },
                    date: this.formWork.work.date,
                    time: this.formWork.work.time,
                    hours: this.formWork.work.hours,
                    minutes: this.formWork.work.minutes,
                    hoursFact: this.formWork.work.hoursFact,
                    minutesFact: this.formWork.work.minutesFact,
                    text: this.formWork.work.text,
                    type: {
                        id: this.formWork.work.type.id,
                        name: this.formWork.work.type.name,
                        title: this.formWork.work.type.title
                    },
                    state: {
                        id: this.formWork.work.state.id,
                        name: this.formWork.work.state.name,
                        title: this.formWork.work.state.title
                    },
                    deleted: false
                };
                this.worksIndexMax++;
                works.push(work);
            }else{
                for(let i=0; i < works.length; i++){
                    if(this.formWork.work.index === works[i].index){
                        work = works[i];
                        work.id = this.formWork.work.id;
                        work.executor.id = this.formWork.work.executor.id;
                        work.executor.name = this.formWork.work.executor.name;
                        work.executor.email = this.formWork.work.executor.email;
                        work.date = this.formWork.work.date;
                        work.time = this.formWork.work.time;
                        work.hours = this.formWork.work.hours;
                        work.minutes = this.formWork.work.minutes;
                        work.hoursFact = this.formWork.work.hoursFact;
                        work.minutesFact = this.formWork.work.minutesFact;
                        work.text = this.formWork.work.text;
                        work.type.id = this.formWork.work.type.id;
                        work.type.name = this.formWork.work.type.name;
                        work.type.title = this.formWork.work.type.title;
                        work.state.id = this.formWork.work.state.id;
                        work.state.name = this.formWork.work.state.name;
                        work.state.title = this.formWork.work.state.title;
                        break;
                    }
                }
            }
            
            let npp = 0;
            
            for(let i=0; i < works.length; i++){
                let work = works[i];
                if(work.deleted){
                    continue;
                }
                npp++;
                work.npp = npp;
            }
            
            this.formWorkClose();
            
        },
        
        formWorkDelete: function(){
            
            this.formWork.message = "";
            
            let work = null;
            
            let works = this.task.works;
            
            for(let i=0; i < works.length; i++){
                if(this.formWork.work.index === works[i].index){
                    work = works[i];
                    work.deleted = true;
                    break;
                }
            }
            
            let npp = 0;
            
            for(let i=0; i < works.length; i++){
                let work = works[i];
                if(work.deleted){
                    continue;
                }
                npp++;
                work.npp = npp;
            }
            
            this.formWorkClose();
            
        }
        
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
    #formWork{
        
        position: absolute;
        
        padding: 5px;
        
        left: 20px;
        top: 50px;
        width: 1000px;
        height: 400px;
        
        border: solid 1px gray;
        border-radius: 5px;
        
        box-shadow: 0 0 2px 1px rgba(122, 122, 122, 0.5);
        
    }
    
    
    .inWaiting{
        
    }
    
    .inWork{
        
        background-color: rgba(255, 255, 220, 1);
        
    }
    
    .done{
        
        background-color: rgba(220, 255, 220, 1);
        
    }
    
    .background-white{
        background-color: white;
    }
    
    .background-light-red{
        background-color: #FFDDDD;
    }
    
    
</style>
