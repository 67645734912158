
export default class BaseFunctions{
    
    static dateToString(date){

        // Преобразует дату к строке вида "yyyy-mm-dd"

        var monthStr = "0" + (date.getMonth() + 1);
        monthStr = monthStr.slice(-2);

        var dayStr = "0" + date.getDate();
        dayStr = dayStr.slice(-2);

        return "" + date.getFullYear() + "-" + monthStr + "-" + dayStr;

    }
    
    static timeToString(time, parameters){
        let secondsShow = false;
        var seconds = ("0" + time.getSeconds()).slice(-2);
        if(typeof(parameters) === "object"){
            if("secondsZero" in parameters){
                seconds = "00";
            }
            if("secondsShow" in parameters){
                secondsShow = true;
            }
        }

        let retVal = "" + ("0" + time.getHours()).slice(-2) + ":" + ("0" + time.getMinutes()).slice(-2);
        if(secondsShow){
            retVal += ":" + seconds;
        }
        
        return retVal;
    }

    static beginMonth(date){
        //var begMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        return new Date("" + date.getFullYear() + "-" + (date.getMonth() + 1) + "-1");
    }

    static endMonth(date){
        var begMonth = this.beginMonth(date);
        begMonth.setMonth(begMonth.getMonth() + 1);
        begMonth.setDate(begMonth.getDate() - 1);
        return begMonth;
    }
    
}



















