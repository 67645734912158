<template>
    <div style="text-align: center; padding-top: 20px;">
        <div v-if="!registration_sucsess" style="display: inline-block; width: 500px; text-align: left;">
            <form v-on:submit.prevent="registration">
                <fieldset style="border-radius: 5px;">
                    <legend style="text-align: center;">Регистрация на сайте</legend>
                    <div style="margin-top: 10px;">
                        <label for="inputName" style="display: inline-block; width: 140px;">Имя и фамилия</label>
                        <input type="text" v-model="name" placeholder="Введите Ваши имя и фамилию" class="form-control" value = "">
                    </div>
                    <div style="margin-top: 10px;">
                        <label for="inputEMail" style="display: inline-block; width: 140px;">E-Mail</label>
                        <input type="email" v-model="email" placeholder="Введите E-Mail" class="form-control" value = "">
                    </div>
                    <div style="margin-top: 10px;">
                        <label for="inputPassword" style="display: inline-block; width: 140px;">Пароль</label>
                        <input type="password" v-model="password" placeholder="Введите пароль" class="form-control">
                    </div>
                    <div style="margin-top: 10px;">
                        <label for="inputPassword" style="display: inline-block; width: 140px;">Повтор пароля</label>
                        <input type="password" v-model="password_confirmation" placeholder="Повторите пароль" class="form-control">
                    </div>
                    <div style="text-align: left; background-color: red; color: white; margin-top: 10px;">
                        <strong>{{ message }}</strong>
                    </div>
                    <div style="text-align: center; margin-top: 15px; margin-bottom: 5px;">
                        <button type="submit" class="btn btn-outline-primary">Зарегистрироваться</button>
                    </div>
                </fieldset>
            </form>
        </div>
        <div v-if="registration_sucsess" style="display: inline-block; width: 700px;">
            <div style="text-align: left; background-color: white; color: gray;">
                <strong>Поздравляем! Вы зарегистрировались на сайте.<br>
                    Теперь Вы можете
                    <div class="link" style="display: inline-block; text-decoration: underline;"
                                     v-if="!isLoggedIn" v-on:click="goToHref(httpPath + 'login');">войти</div>
                    на сайт используя свои Email и пароль.
                </strong>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            httpPath: "",
            name : "",
            email : "",
            password : "",
            password_confirmation : "",
            is_admin : null,
            message: "",
            registration_sucsess: false
        };
    },
    created: function(){
        this.registration_sucsess = false;
        this.httpPath = this.$store.getters.httpPath;
    },
    methods: {
        registration: function () {
            this.message = "";
            if(this.password.length < 6){
                this.message = "Ошибка! Пароль должен быть не менее 6 символов";
                return;
            }
            if(this.password !== this.password_confirmation){
                this.message = "Ошибка! Пароли не совпадают";
                return;
            }
            let data = {
                name: this.name,
                email: this.email,
                password: this.password,
                is_admin: this.is_admin
            };
            this.$store.dispatch('registration', data)
            .then(() => {
               this.registration_sucsess = true;
            })
            .catch(err => this.message = err);
        },
        goToHref: function(href){
            window.scrollTo(0, 0);
            this.$router.push(href);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
</style>
