<template>
    <div id="orgInput" v-bind:class="{'disabled': !enabled}">
        <div style="display: inline-block; border: solid 1px gray; width: 260px; padding: 3px;">
            <span v-show="value.id === null">(не выбран)</span>
            <span v-show="value.id !== null">{{ value.title }}</span>
        </div>
        <div style="display: inline-block; border: solid 1px gray; width: 15px; cursor: pointer; padding: 3px; padding-left: 4px; padding-right: 2px;"
            v-on:click="showFormForSelect();" v-if="enabled">
            &#8801;
        </div>
        <div style="display: inline-block; border: solid 1px gray; width: 15px; cursor: pointer; padding: 3px; padding-left: 5px; padding-right: 1px;"
            v-on:click="clear();" v-if="enabled">
            X
        </div>
        
        <div id="formForSelect" v-show="formForSelect.visible">
            
            <OrgsSelect v-bind:value="value" v-on:input="onChange($event);" v-on:form-close="itemClose();" />
            
        </div>
        
    </div>
</template>

<script>

//import Axios from 'axios'
import OrgsSelect from '../Orgs/OrgsSelect.vue'


export default {
    name: 'UserInput',
    props: {
        elementId: String,
        enabled: {
            type: Boolean,
            default: true
        }
    },  
    components: {
        OrgsSelect
    },
    data: function(){
        return {
            message: "",
            value: {
                id: null,
                name: "",
                title: ""
            },
            formForSelect: {
                visible: false
            }
        };
    },
    created: function(){
        this.$parent.$on('element-update', this.elementUpdate);
    },
    methods: {
        
        showFormForSelect: function(){
            this.formForSelect.visible = true;
            this.$emit('showFormForSelect');
        },
        
        clear: function(){
            
            this.value = {
                id: null,
                name: "",
                title: ""
            };
            
            this.$emit('input', {
                id: null,
                name: "",
                email: ""
            });
            
        },
        
        elementUpdate: function(param){
            
            if(param.elementId === this.elementId){
                let value = param.value;
                this.value.id = value.id;
                this.value.name = value.name;
                this.value.title = value.title;
            }
            
        },
        
        onChange: function(value){
            
            this.value = {
                id: value.id,
                name: value.name,
                title: value.title
            };
            
            this.$emit('input', {
                id: value.id,
                name: value.name,
                title: value.title
            });
            
        },
        
        getNullObject: function(){
            
            let retVal = {
                id: null,
                name: "",
                title: ""
            };
            
            return retVal;
            
        },
        
        itemClose: function(){
            //this.table.enabled = true;
            this.formForSelect.visible = false;
        }
        
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
    #orgInput{
        
        display: inline-block;
        
    }
    
    #formForSelect{
        
        position: absolute;
        z-index: 1001;
        
    }
    
    
</style>
