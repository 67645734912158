<template>
    <div>
        
        <div v-bind:class="{'readonly': mode === 'delete'}">
            <div style="width: 100%; text-align: center; font-size: large; padding-bottom: 10px;">Клиент - {{ org.title }}</div>
            <div>
                <div style="display: inline-block; width: 135px;">Наименование</div>
                <input v-model="org.title" placeholder="Введите наименование" type="text" size="50">
            </div>
            <div style="padding-top: 10px;">
                <div style="display: inline-block; width: 135px;">Телефоны</div>
                <input v-model="org.phones" placeholder="Введите телефоны" type="text" size="30">
            </div>

            <div style="margin-top: 15px; text-align: left; background-color: red; color: white;">
                <strong>{{ message }}</strong>
            </div>
        </div>
        
        <div>
            <div class="btn btn-blue" v-on:click="save();" v-show="mode === 'edit'" style="margin-left: 135px; margin-top: 15px;">Записать</div>
            <div class="btn btn-background-red" v-on:click="del();" v-show="mode === 'delete'" style="margin-left: 135px; margin-top: 15px;">Удалить</div>
            <div class="btn btn-blue" v-on:click="close();" style="margin-left: 135px; margin-top: 15px;">Отмена</div>
        </div>
        
    </div>
</template>

<script>
    
import Axios from 'axios'
    
export default {
    name: 'PageOrg',
    props: {
        msg: String
    },
    data: function(){
        return {
            message: "",
            mode: "",
            org: {
                id: "",
                title: "",
                phones: ""
            }
        };
    },
    created: function(){
        this.$parent.$on('item-add', this.updateNew);
        this.$parent.$on('item-edit', this.updateEdit);
        this.$parent.$on('item-delete', this.updateDelete);
    },
    methods: {
        
        updateNew: function(){
            this.message = "";
            
            this.org.id = "";
            this.org.title = "";
            this.org.phones = "";
            
            this.mode = "edit";
            
        },
        
        updateEdit: function(org){
            this.message = "";
            this.itemFill(org);
            this.mode = "edit";
        },
        
        updateDelete: function(org){
            this.message = "";
            this.itemFill(org);
            this.mode = "delete";
        },
        
        itemFill: function(org){
            this.org.id = org.id;
            this.org.title = org.title;
            this.org.phones = org.phones;
        },
        
        save: function (){
            
            if(this.org.id === ""){
                
                Axios
                    .post('/api/orgs/', {
                        title: this.org.title,
                        phones: this.org.phones
                    })
                    .then(response => {
                        this.org = response.data;
                        //alert("Данные успешно записаны");
                        //this.$router.push({ path: '/adm/org' });
                        this.$emit("item-save");
                        this.close();
                    })
                    .catch(e => {
                        this.message = e;
                    });
                
            }else{
                
                Axios
                    .put('/api/orgs/' + this.org.id, {
                        title: this.org.title,
                        phones: this.org.phones
                    })
                    .then(response => {
                        this.org = response.data;
                        //alert("Данные успешно записаны");
                        //this.$router.push({ path: '/adm/org' });
                        this.$emit("item-save");
                        this.close();
                    })
                    .catch(e => {
                        this.message = e;
                    });
                
            }
            
        },
        del: function(){
            
            Axios
                .delete('/api/orgs/' + this.org.id)
                .then(response => {
                    if(response.status === 204){
                        this.$emit("item-delete");
                        this.close();
                    }else{
                        this.message = "Ошибка! Не предусмотренный код ответа " + response.status + " обратитесь к разработчику.";
                    }
                })
                .catch(e => {
                    this.message = e;
                });
            
        },
        close: function(){
            this.$emit("item-close");
        }
        
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
</style>
