<template>

<div align="justify" style="padding-top: 10px;">
    <div class="h5" align="center" style="padding-top: 10px;">Ошибка 404</div>
    <div align="center">Запрашиваемая страница не найдена</div>
    <div align="center" style="margin-top: -50px;">
        <img src="/img/404.gif" alt="Responsive image">
    </div>
</div>

</template>

<script>
export default {
    name: 'PagePhoto'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
    
</style>

