import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import Axios from 'axios'

Vue.config.productionTip = false;

Vue.prototype.$http = Axios;
const token = localStorage.getItem('token');
if(token){
    Vue.prototype.$http.defaults.headers.common['Accept'] = "application/json";
    Vue.prototype.$http.defaults.headers.common['Authorization'] = token;
}

let httpPath = "/";
if(document.location.host === "localhost:8080"){
    httpPath = "/vue/";
}

Vue.use(Vuex);
Vue.use(VueRouter);

const store = new Vuex.Store({
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        user: {
            id: null,
            name: "",
            title: ""
        },
        
        httpPath: '',
        worksTypes: [],
        worksStates: [],
        windowWidth: 0,
        windowHeight: 0,
        contentWidth: 0,
        contentHeight: 0,
        contentLeft: 0,
        contentTop: 0
    },
    mutations: {
        auth_request(state){
            state.status = 'loading';
        },
        auth_success(state, token, user){
            state.status = 'success';
            state.token = token;
            state.user = user;
        },
        auth_error(state){
            state.status = 'error';
        },
        logout(state){
            state.status = '';
            state.token = '';
            state.user = '';
        },
        
        httpPathSet (state, httpPath) {
            state.httpPath = httpPath;
        },
        userSet (state, user) {
            state.user = user;
        },
        worksTypesSet (state, worksTypes) {
            state.worksTypes = worksTypes;
        },
        worksStatesSet (state, worksStates) {
            state.worksStates = worksStates;
        },
        windowWidth(state, windowWidth){
            state.windowWidth = windowWidth;
        },
        windowHeight(state, windowHeight){
            state.windowHeight = windowHeight;
        },
        contentWidth(state, contentWidth){
            state.contentWidth = contentWidth;
        },
        contentHeight(state, contentHeight){
            state.contentHeight = contentHeight;
        },
        contentLeft(state, contentLeft){
            state.contentLeft = contentLeft;
        },
        contentTop(state, contentTop){
            state.contentTop = contentTop;
        }
    },
    actions: {
        login({commit}, user){
            return new Promise((resolve, reject) => {
                commit('auth_request');
                Axios({url: '/oauth/token', data: user, method: 'POST' })
                .then(resp => {
                    const token = "Bearer " + resp.data.access_token;
                    const user = resp.data.user;
                    localStorage.setItem('token', token);
                    Axios.defaults.headers.common['Authorization'] = token;
                    commit('auth_success', token, user);
                    getMainData();
                    resolve(resp);
                })
                .catch(err => {
                    commit('auth_error');
                    localStorage.removeItem('token');
                    reject(err);
                });
            });
        },
        logout({commit}){
            return new Promise((resolve) => {
                commit('logout');
                localStorage.removeItem('token');
                delete Axios.defaults.headers.common['Authorization'];
                resolve();
            });
        },
        registration({commit}, user){
            return new Promise((resolve, reject) => {
                commit('auth_request');
                Axios({url: '/api/registration', data: user, method: 'POST' })
                .then(resp => {
                    //const token = resp.data.token
                    //const token = null;
                    //const user = resp.data.user;
                    //localStorage.setItem('token', token);
                    //Axios.defaults.headers.common['Authorization'] = token;
                    //commit('auth_success', token, user);
                    //localStorage.removeItem('token');
                    resolve(resp);
                })
                .catch(err => {
                    commit('auth_error', err);
                    localStorage.removeItem('token');
                    reject(err);
                });
            });
        },
        
        setWindowWidth({commit}, windowWidth){
            return new Promise((resolve) => {
                commit('windowWidth', windowWidth);
                resolve();
            });
        },
        setWindowHeight({commit}, windowHeight){
            return new Promise((resolve) => {
                commit('windowHeight', windowHeight);
                resolve();
            });
        },
        setContentWidth({commit}, contentWidth){
            return new Promise((resolve) => {
                commit('contentWidth', contentWidth);
                resolve();
            });
        },
        setContentHeight({commit}, contentHeight){
            return new Promise((resolve) => {
                commit('contentHeight', contentHeight);
                resolve();
            });
        },
        setContentLeft({commit}, contentLeft){
            return new Promise((resolve) => {
                commit('contentLeft', contentLeft);
                resolve();
            });
        },
        setContentTop({commit}, contentTop){
            return new Promise((resolve) => {
                commit('contentTop', contentTop);
                resolve();
            });
        }
    },
    getters : {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        
        httpPath: state => state.httpPath,
        user: state => state.user,
        worksTypes: state => state.worksTypes,
        worksStates: state => state.worksStates,
        windowWidth: state => state.windowWidth,
        windowHeight: state => state.windowHeight,
        contentWidth: state => state.contentWidth,
        contentHeight: state => state.contentHeight,
        contentLeft: state => state.contentLeft,
        contentTop: state => state.contentTop
    }
});

store.commit('httpPathSet', httpPath);

import PageHome from './components/PageHome'
import Page404 from './components/Page404'

import PageLogin from './components/Auth/PageLogin.vue'
import PageRegistration from './components/Auth/PageRegistration.vue'
import PageLogout from './components/Auth/PageLogout.vue'

import PageOrgs from './components/Orgs/PageOrgs.vue'
import PageTasks from './components/Tasks/PageTasks.vue'


const routes = [
    { path: httpPath + '', component: PageHome },
    
    { path: httpPath + 'logout', name: 'logout', component: PageLogout },
    { path: httpPath + 'login', name: 'login', component: PageLogin },
    { path: httpPath + 'registration', name: 'registration', component: PageRegistration },
    
    { path: httpPath + 'orgs', name: 'orgs', component: PageOrgs, meta: {requiresAuth: true} },
    { path: httpPath + 'tasks', name: 'tasks', component: PageTasks, meta: {requiresAuth: true} },
    
    { path: httpPath + '*', component: Page404 }
];

const router = new VueRouter({
    routes // сокращённая запись для `routes: routes`
    , mode: 'history'
});

function getMainData(){
    
    if (store.getters.isLoggedIn) {
        
        Axios
        .get('/api/base-data')
        .then(response => {
            store.commit('userSet', response.data.user);
            store.commit('worksTypesSet', response.data.worksTypes);
            store.commit('worksStatesSet', response.data.worksStates);
        } )
        .catch(e => {
            console.log("Ошибка! При получение базовых данных. " + e);
        });
       
    }
    
}

function resize() {
    store.dispatch('setWindowWidth', window.innerWidth);
    //store.dispatch('setWindowWidth', document.documentElement.clientWidth);
    store.dispatch('setWindowHeight', window.innerHeight);
}

window.onresize = resize;

resize();

getMainData();

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }
        next(httpPath + '/login');
    } else {
        next();
    }
});

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app');




