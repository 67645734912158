<template>
    <div id="app">
        <div v-bind:style="{'position': 'sticky', 'top': '0', 'height': header.height + 'px', 'z-index': '1000', 'background': 'rgb(74, 118, 168)', 'color': 'white', 'text-align': 'left'}">
            <div v-bind:style="{'display': 'inline-block', 'width': linkHome.width + 'px'}"
                v-on:click="hideMainMenuMobile();">
                <h3 id="link-home" v-on:click="goToHref(httpPath + '');" style="margin-bottom: 0;"
                    >ООО "АИС"</h3>
            </div>
            <span class="link" id="link-menu-mobile" v-if="linkMenuMobile.visible" v-on:click="linkMenuMobileOnClick();">&#8801;</span>
        </div>
        
        <div v-on:click="hideMainMenuMobile();">
            
            <div v-if="menu.visible"
                v-bind:style="{'display': 'inline-block', 'vertical-align': 'top', 'background-color': 'rgb(237, 238, 240)', 'height': menu.height + 'px', 'width': menu.width + 'px'}">
                <MainMenu />
            </div>
            
            <div id="content" v-bind:style="{'display': 'inline-block', 'vertical-align': 'top', 'background-color': '#FFFFFF', 'height': menu.height + 'px', 'width': content.width + 'px', 'overflow-y': 'scroll'}"
                >
                <router-view v-on:click="hideMainMenuMobile();"></router-view>
            </div>
            
        </div>
        
        <div id="main-menu-mobile" v-if="mainMenuMobile.visible"
            v-bind:style="{'left': mainMenuMobile.left + 'px', 'top': mainMenuMobile.top + 'px', 'width': mainMenuMobile.width + 'px', 'height': mainMenuMobile.height + 'px'}"
            v-on:click="hideMainMenuMobile();">
            <MainMenu v-on:item-menu-selected="hideMainMenuMobile();" />
        </div>
        
    </div>
</template>

<script>
import MainMenu from './components/MainMenu.vue'

export default {
    name: 'App',
    components: {
        MainMenu
    },
    data: function(){
        return{
            httpPath: "",
            header: {
                height: 0
            },
            menu: {
                visible: false,
                height: 0,
                width: 0
            },
            content: {
                height: 0,
                width: 0
            },
            linkHome: {
                width: 0
            },
            linkMenuMobile: {
                visible: false
            },
            mainMenuMobile: {
                visible: false,
                top: 0,
                left: 0,
                width: 0,
                height: 0
            }
        };
    },
    created: function(){
        this.httpPath = this.$store.getters.httpPath;
    },
    mounted: function(){
        
        this.windowOnResize();
        
    },
    destroyed: function(){
    },
    updated: function(){
    },
    computed:{
        windowWidth: function(){
            return this.$store.getters.windowWidth;
        },
        windowHeight: function(){
            return this.$store.getters.windowHeight;
        },
        isLoggedIn : function(){
            return this.$store.getters.isLoggedIn;
        }
    },
    watch: {
        windowWidth: function(){
            this.windowOnResize();
        },
        windowHeight: function(){
            this.windowOnResize();
        }
    },
    methods: {
        windowOnResize: function(){
            
            this.header.height = 30;
            
            this.menu.width = 200;
            this.menu.visible = true;
            this.linkMenuMobile.visible = false;
            if(this.windowWidth < 1000){
                this.menu.width = 0;
                this.menu.visible = false;
                this.linkMenuMobile.visible = true;
            }else{
                this.mainMenuMobile.visible = false;
            }
            
            this.content.width = this.windowWidth - this.menu.width - 1;
            
            //let contentHeight = document.getElementById('content').clientHeight;
            
            //this.menu.height = contentHeight;
            
            //let menuHeightMin = this.windowHeight - this.header.height;
            
            //if(this.menu.height < menuHeightMin){
            //    this.menu.height = menuHeightMin;
            //}
            
            this.linkHome.width = this.windowWidth - 50;
            
            this.menu.height = this.windowHeight - this.header.height;
            this.content.height = this.menu.height;
            
            this.mainMenuMobile.top = this.header.height + 5;
            this.mainMenuMobile.width = 200;
            if( this.isLoggedIn ){
                this.mainMenuMobile.height = 200;
            }else{
                this.mainMenuMobile.height = 100;
            }
            this.mainMenuMobile.left = this.windowWidth - this.mainMenuMobile.width - 20;
            
            
            
            this.$store.dispatch('setContentWidth', this.content.width);
            this.$store.dispatch('setContentHeight', this.content.height);
            this.$store.dispatch('setContentLeft', this.menu.width);
            this.$store.dispatch('setContentTop', this.header.height);
            
            this.$emit("window-resize");
            
        },
        linkMenuMobileOnClick: function(){
            this.mainMenuMobile.visible = !this.mainMenuMobile.visible;
        },
        hideMainMenuMobile: function(){
            this.mainMenuMobile.visible = false;
        },
        goToHref: function(href){
            window.scrollTo(0, 0);
            this.$router.push(href);
        }
        
    }
}

</script>

<style>
    
    html{
        
        padding: 0;
        
    }
    
    body{
        
        overflow-y: hidden;
        margin: 0;
        
    }
    
    #link-home{
        
        margin-top: 0;
        padding-top: 4px;
        padding-left: 10px;
        cursor: pointer;
        
        display: inline-block;
        
        -ms-user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        
    }
    
    #link-menu-mobile{
        
        color: white;
        border: solid 1px white;
        font-size: larger;
        border-radius: 5px;
        padding-left: 5px;
        padding-right: 5px;
        
        -ms-user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        
    }
    
    #main-menu-mobile{
        
        position: absolute;
        border: solid 1px gray;
        border-radius: 5px;
        background-color: white;
        
    }
    
</style>
