<template>
    <div style="text-align: center; padding-top: 20px;">
        
        <form class="form-horizontal" v-on:submit.prevent="login" style="width: 430px; display: inline-block; text-align: left;">
            <fieldset style="border-radius: 5px;">
                <legend style="text-align: center;">Вход на сайт</legend>
                <div style="margin-top: 10px;">
                    <label for="inputLogin" style="display: inline-block; width: 70px;">Email</label>
                    <input type="email" v-model="email" placeholder="Введите Email" class="form-control" required>
                </div>
                <div style="margin-top: 10px;">
                    <label for="inputPassword" style="display: inline-block; width: 70px;">Пароль</label>
                    <input type="password" v-model="password" placeholder="Введите пароль" class="form-control" required>
                </div>
                <div style="margin-top: 10px; text-align: left; background-color: red; color: white;">
                    <strong>{{ message }}</strong>
                </div>
                <div style="margin-top: 15px; margin-bottom: 5px; text-align: center;">
                    <button type="submit" class="btn btn-outline-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Войти&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                </div>
            </fieldset>
        </form>
        
    </div>
</template>

<script>
export default {
    data(){
        return {
            httpPath: "",
            email: "",
            password: "",
            message: ""
        };
    },
    created: function(){
        this.httpPath = this.$store.getters.httpPath;
    },
    methods: {
        login: function () {
            this.message = "";
            let grant_type = "password";
            let username = this.email;
            let client_id = 2;
            let client_secret = "eItgJgQvA5VDtto4TH2h1YEKfZ0HZVdi0gd0d2Oj";
            let password = this.password;
            this.$store.dispatch('login', { grant_type, client_id, client_secret, username, password })
            .then(() => {
                this.$router.push( this.httpPath + '');
            })
            .catch(err => this.message = err);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
</style>














