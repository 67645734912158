<template>
    <div id="worksStatesSelect">
        <select v-on:change="onChange();" v-model="title">
            <option v-for="worksState in worksStates" v-bind:key="worksState.id">
                {{ worksState.title }}
            </option>
        </select>
    </div>
</template>

<script>
    
//import Axios from 'axios'
    
export default {
    name: 'WorksStatesSelect',
    props: {
        elementId: String
    },
    data: function(){
        return {
            message: "",
            value: {
                id: null,
                name: "",
                title: ""
            },
            worksStates: [],
            title: ""
        };
    },
    created: function(){
        this.worksStates = this.$store.getters.worksStates;
        if(this.worksStates.length > 0){
            this.update();
        }
        this.$parent.$on('element-update', this.elementUpdate);
    },
    computed:{
        worksStatesWhatch: function(){
            return this.$store.getters.worksStates;
        }
    },
    watch: {
        worksStatesWhatch: function(){
            this.worksStates = this.$store.getters.worksStates;
            this.update();
        }
    },
    methods: {
        
        update: function(){
            this.title = "";
            for(let i = 0; i < this.worksStates.length; i++){
                let worksState = this.worksStates[i];
                if(this.value.id === worksState.id){
                    this.title = worksState.title;
                    break;
                }else if(this.value.name === worksState.name){
                    this.title = worksState.title;
                    break;
                }else if(this.value.title === worksState.title){
                    this.title = worksState.title;
                    break;
                }
            }
            if(this.title === ""){
                this.title = "В ожидании";
            }
            for(let i = 0; i < this.worksStates.length; i++){
                let worksState = this.worksStates[i];
                if(worksState.title === this.title){
                    this.$emit('input', {
                        id: worksState.id,
                        name: worksState.name,
                        title: worksState.title
                    });
                    break;
                }
            }
        },
        
        elementUpdate: function(param){
            
            if(param.elementId === this.elementId){
                let value = param.value;
                this.value.id = value.id;
                this.value.name = value.name;
                this.value.title = value.title;
                this.update();
            }
            
        },
        
        onChange: function(){
            
            let value = {
                id: null,
                name: "",
                title: ""
            };
            
            for(let i=0; i < this.worksStates.length; i++){
                let worksState = this.worksStates[i];
                if(worksState.title === this.title){
                    value = {
                        id: worksState.id,
                        name: worksState.name,
                        title: worksState.title
                    };
                    break;
                }
            }
            
            this.$emit('input', value);
            
        }
        
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
    #worksStatesSelect{
        
        display: inline-block;
        
    }
    
</style>
