<template>
    <div id="orgsSelect" v-bind:style="{'left': left + 'px', 'width': width + 'px', 'top': top + 'px', 'height': height + 'px'}">
        
        <div class="btn btn-blue" v-on:click="close();" v-bind:style="{'position': 'absolute', 'left': buttonClose.left + 'px', 'top': buttonClose.top + 'px'}">X</div>
        
        <div style="width: 100%; text-align: center; font-size: large; padding-bottom: 0px;">Клиенты</div>
        
        <div style="margin-top: 0px; text-align: left; background-color: red; color: white;">
            <strong>{{ message }}</strong>
        </div>
        
        <table border="1" style="margin-top: 3px;">
            <tr><th class="text-center">Наименование</th><th class="text-center">Действие</th></tr>
            <tr v-for="org in orgs" v-bind:key="org.id">
                <td>
                    <div class="link" v-on:click="itemSelect(org);">{{ org.title }}</div>
                </td>
                <td>
                    <div class="btn btn-blue" v-on:click="itemSelect(org);" style="margin-left: 3px; margin-right: 3px;">Выборать</div>
                </td>
            </tr>
        </table>
        
    </div>
</template>

<script>
    
import Axios from 'axios'
    
export default {
    name: 'OrgsSelect',
    props: {
        value: Object
    },  
    data: function(){
        return {
            message: "",
            orgs: [],
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            buttonClose: {
                left: 0,
                top: 0
            }
        };
    },
    created: function(){
        
        this.update();
        
        this.$parent.$on('showFormForSelect', this.windowOnResize);
        
    },
    computed:{
        windowWidth: function(){
            return this.$store.getters.windowWidth;
        },
        windowHeight: function(){
            return this.$store.getters.windowHeight;
        },
        contentWidth: function(){
            return this.$store.getters.contentWidth;
        },
        contentHeight: function(){
            return this.$store.getters.contentHeight;
        },
        contentLeft: function(){
            return this.$store.getters.contentLeft;
        },
        contentTop: function(){
            return this.$store.getters.contentTop;
        }
    },
    watch: {
        windowWidth: function(){
            this.windowOnResize();
        },
        windowHeight: function(){
            this.windowOnResize();
        }
    },
    methods: {
        
        windowOnResize: function(){
            
            //this.left = this.contentLeft + 50;
            this.left = 0;
            //this.top = this.contentTop + 30;
            this.top = 0;
            this.width = 500;
            this.height = 500;
            /*
            this.width = this.contentWidth - 100 - (10 + 2); // 10 - margin, 2 - border
            if(this.width > 750){
                this.width = 750;
            }
            if(this.width < 730){
                this.left = this.contentLeft + 5;
                this.width = this.contentWidth - 10 - (10 + 2); // 10 - margin, 2 - border
            }
            this.height = this.contentHeight - 60 - (10 + 2);
            if(this.height > 500){
                this.height = 500;
            }
            if(this.height < 400){
                this.top = 2;
                this.height = this.contentHeight + this.contentTop - 5 - (10 + 2); // 10 - margin, 2 - border
            }
            */
            
            this.buttonClose.top = 3;
            this.buttonClose.left = this.width - 17;
            
        },
        
        update: function(){
            
            Axios
            .post('/api/orgs/list')
            .then(response => {
                this.message = "";
                this.orgs = response.data;
            } )
            .catch(e => {
                this.message = e;
            });
            
        },
        
        itemSelect: function(org){
            
            this.$emit('input', {
                id: org.id,
                name: org.name,
                title: org.title
            });
            this.$emit('form-close');
            
        },
        
        close: function(){
            
            this.$emit('form-close');
            
        }
        
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
    #orgsSelect{
        
        position: absolute;
        /*display: inline-block;*/
        z-index: 1001;
        
        border: solid 1px gray;
        border-radius: 5px;
        padding: 5px;
        
        background-color: white;
        
        box-shadow: 0 0 2px 1px rgba(122, 122, 122, 0.5);
        
    }
    
</style>
