<template>
    <div id="worksTypesSelect">
        <select v-on:change="onChange();" v-model="title">
            <option>(не задан)</option>
            <option v-for="worksType in worksTypes" v-bind:key="worksType.id">
                {{ worksType.title }}
            </option>
        </select>
    </div>
</template>

<script>
    
//import Axios from 'axios'
    
export default {
    name: 'WorksTypesSelect',
    props: {
        elementId: String
    },  
    data: function(){
        return {
            message: "",
            value: {
                id: null,
                name: "",
                title: ""
            },
            worksTypes: [],
            title: ""
        };
    },
    created: function(){
        this.worksTypes = this.$store.getters.worksTypes;
        if(this.worksTypes.length > 0){
            this.update();
        }
        this.$parent.$on('element-update', this.elementUpdate);
    },
    computed:{
        worksTypesWhatch: function(){
            return this.$store.getters.worksTypes;
        }
    },
    watch: {
        worksTypesWhatch: function(){
            this.worksTypes = this.$store.getters.worksTypes;
            this.update();
        }
    },
    methods: {
        
        update: function(){
            this.title = "";
            for(let i = 0; i < this.worksTypes.length; i++){
                let worksType = this.worksTypes[i];
                if(this.value.id === worksType.id){
                    this.title = worksType.title;
                    break;
                }else if(this.value.name === worksType.name){
                    this.title = worksType.title;
                    break;
                }else if(this.value.title === worksType.title){
                    this.title = worksType.title;
                    break;
                }
            }
            if(this.title === ""){
                this.title = "(не задан)";
                this.$emit('input', {
                    id: null,
                    name: "",
                    title: ""
                });
            }else{
                for(let i = 0; i < this.worksTypes.length; i++){
                    let worksType = this.worksTypes[i];
                    if(worksType.title === this.title){
                        this.$emit('input', {
                            id: worksType.id,
                            name: worksType.name,
                            title: worksType.title
                        });
                        break;
                    }
                }
            }
            
        },
        
        elementUpdate: function(param){
            
            if(param.elementId === this.elementId){
                let value = param.value;
                this.value.id = value.id;
                this.value.name = value.name;
                this.value.title = value.title;
                this.update();
            }
            
        },
        
        onChange: function(){
            
            let value = {
                id: null,
                name: "",
                title: ""
            };
            
            for(let i=0; i < this.worksTypes.length; i++){
                let worksType = this.worksTypes[i];
                if(worksType.title === this.title){
                    value = {
                        id: worksType.id,
                        name: worksType.name,
                        title: worksType.title
                    };
                    break;
                }
            }
            
            this.$emit('input', value);
            
        }
        
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
    #worksTypesSelect{
        
        display: inline-block;
        
    }
    
</style>
