<template>
    <div style="padding: 5px;">
        
        <div id="table" v-bind:class="{'disabled': !table.enabled}">
            
            <div style="margin-top: 0; text-align: left; background-color: red; color: white;">
                <strong>{{ message }}</strong>
            </div>
            
            <div style="margin-bottom: 10px;">
                <div style="display: inline-block; width: 120px; font-size: x-large;">Задачи</div>
                <div class="btn btn-blue" v-on:click="itemAdd();">Создать</div>
                <div style="display: inline-block; margin-left: 20px; font-size: large;">Период с</div>
                <input type="date" v-model="filter.dateBeg" v-on:input="filterChanged();" style="margin-left: 10px;">
                <div style="display: inline-block; margin-left: 10px; font-size: large;">по</div>
                <input type="date" v-model="filter.dateEnd" v-on:input="filterChanged();" style="margin-left: 10px;">
                <div class="btn btn-blue" v-on:click="update();" style="margin-left: 10px;">Обновить</div>
            </div>
            
            <div style="margin-bottom: 10px;">
                <div style="display: inline-block; margin-left: 0px; font-size: large;">Исполнитель</div>
                <UserInput v-model="filter.executor" element-id="filter-executor" v-on:input="filterChanged();" style="margin-left: 10px;" />
                <div style="display: inline-block; margin-left: 10px; font-size: large;">Клиент</div>
                <OrgInput v-model="filter.client" element-id="filter-client" v-on:input="filterChanged();" style="margin-left: 10px;" />
            </div>
            
            <div style="margin-bottom: 10px;">
                
                <div v-bind:class="{'filterForState': true, 'filterForStateActive': filterForState === ''}"
                    v-on:click="setFilterForState('')">
                    Всего: {{ tasksAllCount }}
                </div>
                <div v-bind:class="{'filterForState': true, 'filterForStateActive': filterForState === 'ВОжидании'}"
                    v-on:click="setFilterForState('ВОжидании')">
                    В ожидании: {{ tasksInWaitingCount }}
                </div>
                <div v-bind:class="{'filterForState': true, 'filterForStateActive': filterForState === 'ВРаботе'}"
                    v-on:click="setFilterForState('ВРаботе')">
                    В работе: {{ tasksInWorkCount }}
                </div>
                <div v-bind:class="{'filterForState': true, 'filterForStateActive': filterForState === 'Выполнено'}"
                    v-on:click="setFilterForState('Выполнено')">
                    Выполнено: {{ tasksDoneCount }}
                </div>
                
            </div>
            
            <div v-for="task in tasks" v-bind:key="task.id" v-on:click="itemEdit(task);"
                 v-bind:class="{'taskItem': true, 'inWaiting': task.stateName ==='ВОжидании', 'inWork': task.stateName ==='ВРаботе', 'done': task.stateName === 'Выполнено'}"
                 v-show="task.stateName === filterForState || filterForState === ''">
                <div>
                    <div style="display: inline-block; width: 90px; height: 20px; vertical-align: top;">
                        Автор:
                    </div>
                    <div style="display: inline-block; width: 200px; height: 20px; vertical-align: top;">
                        {{ task.creatorName }}
                    </div>
                </div>
                <div>
                    <div style="display: inline-block; width: 90px; height: 20px; vertical-align: top;">
                        Исп-тель:
                    </div>
                    <div style="display: inline-block; width: 200px; height: 20px; vertical-align: top;">
                        {{ task.executorName }}
                    </div>
                </div>
                <div>
                    <div style="display: inline-block; width: 90px; height: 20px; vertical-align: top;">
                        Клиент:
                    </div>
                    <div style="display: inline-block; width: 200px; height: 20px; vertical-align: top; overflow-y: hidden; overflow-x: hidden; ">
                        {{ task.clientTitle }}
                    </div>
                </div>
                <div>
                    <div style="display: inline-block; width: 90px; height: 20px; vertical-align: top;">
                        Срок:
                    </div>
                    <div style="display: inline-block; width: 200px; height: 20px; vertical-align: top; overflow-y: hidden; overflow-x: hidden; ">
                        {{ task.dateMax }}
                    </div>
                </div>
                <div>
                    <div style="display: inline-block; width: 90px; height: 20px; vertical-align: top;">
                        Статус:
                    </div>
                    <div style="display: inline-block; width: 200px; height: 20px; vertical-align: top; overflow-y: hidden; overflow-x: hidden; ">
                        {{ task.stateTitle }}
                    </div>
                </div>
                <div>
                    <div style="display: inline-block; width: 100%; height: 20px;">
                        Описание:
                    </div>
                    <div style="display: inline-block; width: 290px; height: 100px; overflow-y: auto; border: solid 1px gray; border-radius: 3px; padding: 3px;">
                        {{ task.text }}
                    </div>
                </div>
            </div>
            
            <br><br>
            
        </div>
        
        <div id="item" v-show="item.visible" v-bind:class="{'background-light-red': item.openForDelete, 'background-white': !item.openForDelete }"
             v-bind:style="{'left': item.left + 'px', 'width': item.width + 'px', 'top': item.top + 'px', 'height': item.height + 'px'}">
            
            <div class="btn btn-blue" v-on:click="itemClose();" v-bind:style="{'position': 'absolute', 'left': item.buttonClose.left + 'px', 'top': item.buttonClose.top + 'px'}">X</div>
            
            <PageTask v-on:item-close="itemClose();" v-on:item-save="update();" v-on:item-delete="update();" />
            
        </div>
        
        <div v-show="false">
            <br>
            Вид работ <WorksTypesSelect v-model="worksType" /> |{{ worksType }}|
            <br>
            <br>
            Состояние <WorksStatesSelect v-model="worksState" /> |{{ worksState }}|
        </div>
        
    </div>
</template>

<script>

import BaseFunctions from '../../base_functions.js';

import Axios from 'axios'
import PageTask from './PageTask.vue'
import WorksTypesSelect from '../BaseData/WorksTypesSelect.vue'
import WorksStatesSelect from '../BaseData/WorksStatesSelect.vue'
import UserInput from '../Users/UserInput.vue'
import OrgInput from '../Orgs/OrgInput.vue'

    
export default {
    name: 'PageTasks',
    props: {
      msg: String
    },
    components: {
        PageTask,
        WorksTypesSelect,
        WorksStatesSelect,
        UserInput,
        OrgInput
    },
    data: function(){
        return {
            message: "",
            filter: {
                dateBeg: null,
                dateEnd: null,
                executor: {
                    id: null,
                    name: "",
                    email: ""
                },
                client: {
                    id: null,
                    name: "",
                    title: ""
                }
            },
            filterForState: "",
            tasksAllCount: 0,
            tasksInWaitingCount: 0,
            tasksInWorkCount: 0,
            tasksDoneCount: 0,
            tasks: [],
            table: {
                enabled: true
            },
            item: {
                visible: false,
                openForDelete: false,
                left: 0,
                top: 0,
                width: 0,
                height: 0,
                buttonClose: {
                    left: 0,
                    top: 0
                }
            }
            
            ,
            worksType: {
                id: null,
                name: "",
                title: "",
                order: 0
            },
            worksState: {
                id: null,
                name: "",
                title: "",
                order: 0
            }
            
        };
    },
    created: function(){
        
        let now = new Date();
        let endMonth = BaseFunctions.endMonth(now);
        
        this.filter.dateBeg = BaseFunctions.dateToString(now);
        this.filter.dateEnd = BaseFunctions.dateToString(endMonth);
        
        this.filterForState = "";
        
        this.$parent.$on('window-resize', this.windowOnResize);
        
        this.table.enabled = true;
        this.item.visible = false;
        this.item.openForDelete = false;
        
    },
    mounted: function(){
        
        this.windowOnResize();
        
        if(this.user.id !== null){
            this.filter.executor.id = this.user.id;
            this.filter.executor.name = this.user.name;
            this.filter.executor.email = this.user.email;
            this.$emit("element-update", {
                elementId: "filter-executor",
                value: this.filter.executor
            });
            this.update();
        }
        
    },
    computed:{
        user: function(){
            return this.$store.getters.user;
        },
        contentWidth: function(){
            return this.$store.getters.contentWidth;
        },
        contentHeight: function(){
            return this.$store.getters.contentHeight;
        },
        contentLeft: function(){
            return this.$store.getters.contentLeft;
        },
        contentTop: function(){
            return this.$store.getters.contentTop;
        }
    },
    watch: {
        user: function(){
            this.filter.executor.id = this.user.id;
            this.filter.executor.name = this.user.name;
            this.filter.executor.email = this.user.email;
            this.$emit("element-update", {
                elementId: "filter-executor",
                value: this.filter.executor
            });
            this.update();
        }
    },
    methods: {
        windowOnResize: function(){
            
            this.item.left = this.contentLeft + 50;
            this.item.top = this.contentTop + 30;
            this.item.width = this.contentWidth - 100 - (10 + 2); // 10 - margin, 2 - border
            if(this.item.width > 1100){
                this.item.width = 1100;
            }
            if(this.item.width < 800){
                this.item.left = this.contentLeft + 5;
                this.item.width = this.contentWidth - 10 - (10 + 2); // 10 - margin, 2 - border
            }
            this.item.height = this.contentHeight - 60 - (10 + 2);
            if(this.item.height > 520){
                this.item.height = 520;
            }
            if(this.item.height < 500){
                this.item.top = 2;
                this.item.height = this.contentHeight + this.contentTop - 5 - (10 + 2); // 10 - margin, 2 - border
            }
            
            this.item.buttonClose.top = 3;
            this.item.buttonClose.left = this.item.width - 17;
            
        },
        update: function(){
            
            Axios
            .post('/api/tasks/list', {
                filter: this.filter
            })
            .then(response => {
                this.message = "";
                this.tasks = response.data;
                this.updateStat();
            } )
            .catch(e => {
                this.message = e;
            });
            
        },
        updateStat: function(){
            
            this.tasksAllCount = 0;
            this.tasksInWaitingCount = 0;
            this.tasksInWorkCount = 0;
            this.tasksDoneCount = 0;
            
            for(let i = 0; i < this.tasks.length; i++){
                
                let task = this.tasks[i];
                
                this.tasksAllCount++;
                if(task.stateName === "ВОжидании"){
                    this.tasksInWaitingCount++;
                }
                if(task.stateName === "ВРаботе"){
                    this.tasksInWorkCount++;
                }
                if(task.stateName === "Выполнено"){
                    this.tasksDoneCount++;
                }
                
            }
            
        },
        itemAdd: function(){
            this.$emit('item-add');
            this.table.enabled = false;
            this.item.visible = true;
            this.item.openForDelete = false;
        },
        itemEdit: function(task){
            
            this.$emit("item-edit", task);
            this.table.enabled = false;
            this.item.visible = true;
            this.item.openForDelete = false;
            
        },
        itemDelete: function(task){
            if(task){
                //
            }
            /*
            this.$emit("item-delete", org);
            this.table.enabled = false;
            this.item.visible = true;
            this.item.openForDelete = true;
            */
        },
        itemClose: function(){
            this.table.enabled = true;
            this.item.visible = false;
        },
        filterChanged: function(){
            this.update();
        },
        setFilterForState: function(name){
            this.filterForState = name;
        }
    }
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
    .taskItem{
        
        display: inline-block;
        
        width: 300px;
        
        cursor: pointer;
        
        padding: 5px;
        margin-right: 10px;
        margin-bottom: 10px;
        
        border: solid 1px blue;
        border-radius: 5px;
        
    }
    
    .inWaiting{
        
        box-shadow: 0 0 2px 2px rgba(150, 150, 150, 0.5);
        
    }
    
    .inWork{
        
        box-shadow: 0 0 2px 2px rgba(150, 150, 0, 1);
        background-color: rgba(255, 255, 220, 1);
        
    }
    
    .done{
        
        box-shadow: 0 0 2px 2px rgba(0, 150, 0, 0.5);
        background-color: rgba(220, 255, 220, 1);
        
    }
    
    #item{
        
        position: absolute;
        z-index: 1001;
        
        border: solid 1px gray;
        border-radius: 5px;
        padding: 5px;
        
        box-shadow: 0 0 2px 1px rgba(122, 122, 122, 0.5);
        
    }
    
    .filterForState{
        
        display: inline-block;
        /*width: 180px;*/
        font-size: large;
        cursor: pointer;
        
        border: solid 3px #AAAAAA;
        border-radius: 5px;
        
        margin-right: 15px;
        padding-left: 10px;
        padding-right: 10px;
        
        -ms-user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        
    }
    
    .filterForStateActive{
        
        border: solid 3px #4444FF;
        
    }
    
    .background-white{
        background-color: white;
    }
    
    .background-light-red{
        background-color: #FFDDDD;
    }
    
</style>
