<template>
    <div style="text-align: center; padding-top: 20px;">
        {{ message }}
        <form class="form-horizontal" v-on:submit.prevent="logout" style="width: 330px; display: inline-block; text-align: left;">
            <fieldset style="border-radius: 5px;">
                <legend style="text-align: center;">Выход</legend>
                <div style="margin-top: 15px; margin-bottom: 5px; text-align: center;">
                    <button type="submit" class="btn btn-outline-primary">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Выйти&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                </div>
            </fieldset>
        </form>
        
    </div>
</template>

<script>
export default {
    data(){
        return {
            httpPath: "",
            email: "",
            password: "",
            message: ""
        };
    },
    created: function(){
        this.httpPath = this.$store.getters.httpPath;
    },
    methods: {
        logout: function () {
            this.$store.dispatch('logout')
            .then(() => {
                this.$router.push( this.httpPath + 'login');
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
</style>














