<template>
    <div style="padding-left: 10px; padding-top: 10px;">
        
        <div class="link" style="padding-top: 10px;"
             v-if="!isLoggedIn" v-on:click="goToHref(httpPath + 'login');">Войти</div>
        <div class="link" style="padding-top: 10px;"
             v-if="!isLoggedIn" v-on:click="goToHref(httpPath + 'registration');">Зарегистрироваться</div>
        
        <div class="link" style="padding-top: 10px;"
             v-if="isLoggedIn" v-on:click="goToHref(httpPath + 'orgs');">Клиенты</div>
        <div class="link" style="padding-top: 10px;"
             v-if="isLoggedIn" v-on:click="goToHref(httpPath + 'tasks');">Задачи</div>
        
        
        <div class="link" style="padding-top: 20px;"
             v-if="isLoggedIn" v-on:click="logout()">Выйти</div>
        
    </div>
</template>

<script>
export default {
    data: function(){
        return{
            httpPath: ""
        };
    },
    created: function(){
        this.httpPath = this.$store.getters.httpPath;
    },
    computed : {
        isLoggedIn : function(){
            return this.$store.getters.isLoggedIn;
        }
    },
    methods: {
        logout: function () {
            this.goToHref(this.httpPath + 'logout');
        },
        goToHref: function(href){
            window.scrollTo(0, 0);
            this.$router.push(href);
            this.$emit("item-menu-selected");
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
</style>
