<template>
    <div style="padding: 5px;">
        
        <div id="table" v-bind:class="{'disabled': !table.enabled}">
            
            <div style="margin-top: 15px; text-align: left; background-color: red; color: white;">
                <strong>{{ message }}</strong>
            </div>
            
            <div style="width: 100%; text-align: center; font-size: x-large;">Клиенты</div>
            
            <div class="btn btn-blue" v-on:click="itemAdd();">Создать</div>
            
            <table border="1" style="margin-top: 3px;">
                <tr><th class="text-center">Наименование</th><th class="text-center">Телефоны</th><th class="text-center">Действие</th></tr>
                <tr v-for="org in orgs" v-bind:key="org.id">
                    <td>
                        <div class="link" v-on:click="itemEdit(org);">{{ org.title }}</div>
                    </td>
                    <td>
                        <div class="link" v-on:click="itemEdit(org);">{{ org.phones }}</div>
                    </td>
                    <td>
                        <div class="btn btn-blue" v-on:click="itemEdit(org);" style="margin-left: 3px; margin-right: 3px;">Изменить</div>
                        <div class="btn btn-red" v-on:click="itemDelete(org);" style="margin-left: 3px; margin-right: 3px;">Удалить</div>
                    </td>
                </tr>
            </table>
        </div>
        
        <div id="item" v-show="item.visible" v-bind:class="{'background-light-red': item.openForDelete, 'background-white': !item.openForDelete }"
             v-bind:style="{'left': item.left + 'px', 'width': item.width + 'px', 'top': item.top + 'px', 'height': item.height + 'px'}">
            
            <div class="btn btn-blue" v-on:click="itemClose();" v-bind:style="{'position': 'absolute', 'left': item.buttonClose.left + 'px', 'top': item.buttonClose.top + 'px'}">X</div>
            
            <PageOrg v-on:item-close="itemClose();" v-on:item-save="update();" v-on:item-delete="update();" />
            
        </div>
        
    </div>
</template>

<script>
    
import Axios from 'axios'
import PageOrg from './PageOrg.vue'

    
export default {
    name: 'PageOrgs',
    props: {
      msg: String
    },
    components: {
        PageOrg
    },
    data: function(){
        return {
            message: "",
            orgs: [],
            table: {
                enabled: true
            },
            item: {
                visible: false,
                openForDelete: false,
                left: 0,
                top: 0,
                width: 0,
                height: 0,
                buttonClose: {
                    left: 0,
                    top: 0
                }
            }
            
        };
    },
    created: function(){
        
        this.$parent.$on('window-resize', this.windowOnResize);
        
        this.table.enabled = true;
        this.item.visible = false;
        this.item.openForDelete = false;
        
        this.update();
        
    },
    mounted: function(){
        this.windowOnResize();
    },
    computed:{
        contentWidth: function(){
            return this.$store.getters.contentWidth;
        },
        contentHeight: function(){
            return this.$store.getters.contentHeight;
        },
        contentLeft: function(){
            return this.$store.getters.contentLeft;
        },
        contentTop: function(){
            return this.$store.getters.contentTop;
        }
    },
    methods: {
        windowOnResize: function(){
            this.item.left = this.contentLeft + 50;
            this.item.top = this.contentTop + 30;
            this.item.width = this.contentWidth - 100 - (10 + 2); // 10 - margin, 2 - border
            if(this.item.width > 750){
                this.item.width = 750;
            }
            if(this.item.width < 730){
                this.item.left = this.contentLeft + 5;
                this.item.width = this.contentWidth - 10 - (10 + 2); // 10 - margin, 2 - border
            }
            this.item.height = this.contentHeight - 60 - (10 + 2);
            if(this.item.height > 400){
                this.item.height = 400;
            }
            if(this.item.height < 250){
                this.item.top = 2;
                this.item.height = this.contentHeight + this.contentTop - 5 - (10 + 2); // 10 - margin, 2 - border
            }
            
            this.item.buttonClose.top = 3;
            this.item.buttonClose.left = this.item.width - 17;
            
        },
        update: function(){
            
            Axios
            .post('/api/orgs/list')
            .then(response => {
                this.message = "";
                this.orgs = response.data;
            } )
            .catch(e => {
                this.message = e;
            });
            
        },
        itemAdd: function(){
            this.$emit('item-add');
            this.table.enabled = false;
            this.item.visible = true;
            this.item.openForDelete = false;
        },
        itemEdit: function(org){
            this.$emit("item-edit", org);
            this.table.enabled = false;
            this.item.visible = true;
            this.item.openForDelete = false;
        },
        itemDelete: function(org){
            this.$emit("item-delete", org);
            this.table.enabled = false;
            this.item.visible = true;
            this.item.openForDelete = true;
        },
        itemClose: function(){
            this.table.enabled = true;
            this.item.visible = false;
        }
    }
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
    #item{
        
        position: absolute;
        z-index: 1001;
        
        border: solid 1px gray;
        border-radius: 5px;
        padding: 5px;
        
        box-shadow: 0 0 2px 1px rgba(122, 122, 122, 0.5);
        
    }
    
    .background-white{
        background-color: white;
    }
    
    .background-light-red{
        background-color: #FFDDDD;
    }
    
</style>
